<template>
    <div class="contact-container">
        <div class="address-img">
            <!-- <div class="container-fluid px-0">
                <div class="row g-4">
                    <div class="col-md-6">
                        <img src="../assets/contact/image.png" alt="中国办公室" class="img-fluid">
                    </div>
                    <div class="col-md-6">
                        <img src="../assets/contact/image2.png" alt="瑞士总部" class="img-fluid">
                    </div>
                </div>
            </div> -->
        </div>
        <div class="contact-content">
            <div class="container-fluid px-0">
                <div class="row g-4">
                    <div class="col-md-6">
                        <div class="china-office">
                            <h2>中国</h2>
                            <div class="info">
                                <p>北京瑞思格美艺术设计有限公司</p>
                                <p>地址 (Add)：北京市东城区安化北里北京上舍家园2号楼</p>
                                <p>E-mail: info@swisstyle.com</p>
                                <p>电话 (Tel)：+86 10 6713 1524     &nbsp; &nbsp; &nbsp;    +86 10 8755 1160(市场部)</p>
                                <p>手机 (Tel)：13911579496 (同微信)</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="swiss-office">
                            <h2>瑞士</h2>
                            <div class="info">
                                <p>HEADQUARTER: SWISSTYLE   GRAPHIC DESIGN</p>
                                <p>COMMUNICATION & PREPRESS</p>
                                <p>Avenue de la Gare 77 1022 Chavannes-près-Renens Vaud Switzerland</p>
                                <p>E-mail: info@swisstyle.com</p>
                                <p>Tel: +41 21888 3287</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="company-info">
            <img src="../assets/contact/image3.png" alt="" class="img-fluid">
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>

<style lang="less" scoped>
.contact-container {
    width: 100%;
    // min-height: 100vh;
    background: #fff;

    .contact-content {
        padding: 60px 40px;
        .china-office {
            padding-left: 120px;
        }
        .swiss-office {
            padding-left: 120px;
        }

        h2 {
            font-size: 24px;
            color: #333;
            margin-bottom: 20px;
            font-weight: 500;
        }

        .info {
            p {
                font-size: 16px;
                color: #666;
                line-height: 1.8;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .contact-container {
       

        .contact-content {
            padding: 40px 0;

            .china-office,
            .swiss-office {
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
            }

            h2 {
                font-size: 20px;
                margin-bottom: 15px;
                margin-top: 20px;
            }

            .info {
                p {
                    font-size: 14px;
                    line-height: 1.6;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .contact-container {
        .contact-content {
            .china-office,
            .swiss-office {
                padding-left: 60px;
            }
        }
    }
}
</style>